/*
|==========================================================================
| Sticky Header
|==========================================================================
| 
| This function gives us the option to implement a sticky header, with
| custom options for class, height, and calculation.
|
|==========================================================================
|
*/

function check_size() {

    var scroll_pos = jQuery(window).scrollTop();


    var height = 68 + 25;


    const mql = window.matchMedia("screen and (max-width: 1023px)");

    // console.log(scroll_pos);

    if (scroll_pos > height) {

        jQuery('body header.header').addClass('mini');

    } else {

        jQuery('body header.header').removeClass('mini');

    }

    // if (mql.matches || scroll_pos >= height) {
    //     jQuery('.mobile-menu').removeClass('hide');
    // } else {
    //     jQuery('.mobile-menu').addClass('hide');
    // }
}

function sticky_header() {

    check_size();

    jQuery(window).on('resize', function () {
        check_size();
    });
}